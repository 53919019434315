<template>
    <CompleteRegistration
            :sname="getUSER.userName.sname"
            :name="getUSER.userName.name"
            :mname="getUSER.userName.mname"
            :birthplace="getUSER.birthplace"
            :birthday="getUSER.birthday"
            :registrationAddress="getUSER.registrationAddress"
            :actualAddress="getUSER.actualAddress"
            :inn-list="innList"
            :inn="getUSER.inn"
            :passport-view="getUSER.passport"
            :snils-view="getUSER.snils"
            :timer="timer"
            :show-checking="isShow"
            :showModal="showModal"
            :emailConfirmed="get_IS_EMAIL_CONFIRMED"
            :regCompleted="regCompleted"
            :registration-method="getUSER.registrationMethod"
            :issued-by-list="issuedByList"
            @submit="submit"
            @endsTimer="endsTimer"
            @sendAgain="sendAgain()"
            @veryfied="veryfied"
            @fileChange="fileChange"
            @close="showModal = false"
            @editDoc="editDoc"
    />
</template>

<script>
    import CompleteRegistration from "@/components/pages/CompleteRegistration";
    import {mapActions, mapGetters} from "vuex";
    import api from "@/api"
    import moment from "moment"

    export default {
        name: "CompleteRegistrationView",
        components: {CompleteRegistration},
        data() {
            return {
                timer: false,
                phone: "",
                loading: false,
                isShow: false,
                showModal: false,
                regCompleted: false,
                innList: [],
                issuedByList: [],
            };
        },
        computed: {
            ...mapGetters("errorModule", ["getERROR"]),
            ...mapGetters("AuthModule", ["getUSER"]),
            ...mapGetters("regModule", ["get_IS_EMAIL_CONFIRMED"]),
        },
        methods: {
            ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
            ...mapActions("AuthModule", ["ADD_USER_DATA"]),
            ...mapActions("regModule", [
                "SEND_USER_DATA",
                "SET_EMAIL_CONFIRMED",
                "MODIFY_USER",
                "SEND_FILES",
            ]),
            async onSearchINN(docNumber) {
                const m = moment(this.getUSER.birthday * 1000)
                const body = {
                    "fam": this.getUSER.userName.sname,
                    "nam": this.getUSER.userName.name,
                    "otch": this.getUSER.userName.mname,
                    "bdate": m.format("DD.MM.YYYY"),
                    "docno": docNumber
                }
                const result = await api.searchINN({data: body})
                if (result.success) {
                    this.innList = result.result.items.map(el => el[Object.keys(el)[0]])
                    this.MODIFY_USER({update: true, key: "inn", value: this.innList[0]})
                }
            },
            async editDoc(newVal) {
                this.MODIFY_USER(newVal);
                if (newVal.doc === "passport" && newVal.key === "number" && newVal.value !== "") {
                    await this.onSearchINN(newVal.value)
                }
                if (newVal.doc === "passport" && newVal.key === "department" && newVal.value !== "") {
                    const response = await api.searchPassportQuery({data: {passport_code: newVal.value}})
                    if (response.success && response.result.data) this.issuedByList = response.result.data.map(el => el.name);
                    else this.$toast.error("Не найден код подразделения. Пожалуйста, введите данные в поле \"Кем выдан паспорт\" вручную.");
                }
            },

            fileChange(file, type) {
                let typedData = this.getUSER[type];
                const numType = type === "passport" ? 2 : type === "snils" ? 1 : 0;
                typedData.scan.file.length = 0;
                typedData.scan.type = numType;
                typedData.scan.file.push({name: file.name, data: file});
                // for save image to local storage
                //       var reader = new FileReader();
                //       reader.onload = () => {
                //           typedData.scan.file.push({ name: file.name, data: reader.result })
                //           typedData.scan.type = numType
                //           this.ADD_USER_DATA({[type] : { ...typedData }})
                //       }
                //       reader.readAsDataURL(file)
                //
            },
            endsTimer(timer) {
                this.timer = timer;
            },
            sendAgain() {
                if (!this.timer) {
                    this.phone = this.getUSER.phone;
                    this.REQUEST_SMS({phone: this.phone, action: 'registration_complete'}).then((success) => {
                        if (success) {
                            this.timer = true;
                        } else {
                            this.$toast.error(`${this.getERROR[0]}`);
                        }
                    });
                }
            },
            veryfied(code) {
                this.CONFIRM_SMS({phone: this.phone, key: code}).then((success) => {
                    if (success) {
                        this.SEND_FILES().then((resp) => {
                            if (resp) {
                                this.SEND_USER_DATA().then((success) => {
                                    this.loading = false;
                                    this.SET_EMAIL_CONFIRMED(false);
                                    this.showModal = true;
                                    this.regCompleted = true;
                                    if (!success) {
                                        this.$toast.error(`${this.getERROR[0]}`);
                                    }
                                });
                            } else {
                                this.$toast.error(`${this.getERROR[0]}`);
                            }
                        });
                    }
                });
            },
            submit() {
                if (!this.timer) {
                    this.phone = this.getUSER.phone;
                    this.REQUEST_SMS({phone: this.phone, action: 'registration_complete'}).then((success) => {
                        if (success) {
                            this.timer = true;
                            this.isShow = true;
                            // this.SAVE_USER_DATA(data)
                        } else {
                            this.$toast.error(`${this.getERROR[0]}`);
                        }
                    });
                }
            },
        },
        mounted() {
            this.get_IS_EMAIL_CONFIRMED
                ? (this.showModal = this.get_IS_EMAIL_CONFIRMED)
                : "";
        },
    };
</script>

<style scoped lang="scss"></style>
